import { AnalyticsPropertyValues, AnalyticsEventDefinitions, AnalyticsEventProperties } from "../enums/AnalyticsEvents";
import { Signal } from "./Signal";

export interface AnalyticPropertyValues {
    [key: string]: string | number | boolean | null;
}

// At present, it appears that this is necessary to be a class because the dispatchEvent is intended to be overridden
// TODO: Look at ways to make this more OOP, where we have a type-safe way of overriding the dispatchEvent method, and guaranteeing it is overridden
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Analytics {
    private static _isImpersonating: boolean = false;
    public static setIsImpersonating(newValue: boolean) {
        this._isImpersonating = newValue;
    }

    public static eventSignal = new Signal<(args: { name: string; properties: any }) => void>();

    public static transformEventProperties<EventKey extends keyof AnalyticsEventDefinitions>(
        properties: AnalyticsEventProperties<EventKey> | undefined,
    ): AnalyticPropertyValues {
        const propertyValues: AnalyticPropertyValues = {};

        if (!properties) {
            return propertyValues;
        }

        for (const key of Object.keys(properties) as Array<keyof AnalyticsEventDefinitions[EventKey]>) {
            let value = properties[key];
            if (typeof value === "boolean") {
                value = (
                    value ? AnalyticsPropertyValues.True : AnalyticsPropertyValues.False
                ) as AnalyticsEventDefinitions[EventKey][keyof AnalyticsEventDefinitions[EventKey]];
            }
            if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
                propertyValues[key as string] = value;
            }
        }
        return propertyValues;
    }

    public static trackEvent<EventKey extends keyof AnalyticsEventDefinitions>(
        event: EventKey,
        properties?: AnalyticsEventProperties<EventKey>,
    ) {
        const propertyValues = this.transformEventProperties(properties);
        console.log("Analytics Event:", event.toString(), propertyValues);
        Analytics.dispatchEvent(event.toString(), propertyValues);
    }

    // Clients can overwrite this method with their own metrics emission strategy,
    // or they can handle the event via `Analytics.eventSignal.addHandler(fn)`
    public static dispatchEvent(name: string, properties: any): void {
        if (this._isImpersonating) {
            // we don't want to record any analytics events when it's actually a staff member impersonating
            return;
        }
        this.eventSignal.dispatch({ name, properties });
    }
}
