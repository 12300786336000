import { Analytics, AnalyticPropertyValues } from "ynab_shared_library/app/base/utilities/Analytics";
import { logAnalyticsEvent, loginAnalytics, logoutAnalytics } from "ynab_shared_library/app/web/utilities/analytics";
import { AnalyticsEvents, AnalyticsProperties } from "../analytics-events";

(() => {
    const RETRY_DELAY_TIME = 100;

    class YNABAnalytics {
        retryWithDelay(fn: () => void) {
            return window.setTimeout(() => {
                fn();
            }, RETRY_DELAY_TIME);
        }

        reportError(error) {
            return window.Rollbar && window.Rollbar.error(error);
        }

        trackEvent(
            eventName: AnalyticsEvents,
            eventProperties: AnalyticPropertyValues = {},
            options = { flush: false },
        ) {
            if (this._isImpersonating()) {
                return;
            }
            logAnalyticsEvent(eventName, eventProperties, options);
        }

        initMobile() {
            this.trackEvent = this.trackEventMobile;
        }

        trackEventMobile(eventName, eventProperties: AnalyticPropertyValues = {}, _options) {
            if (this._isImpersonating()) {
                return;
            }
            const supportedProperties = Analytics.transformEventProperties(eventProperties);

            YNABMobile.trackEvent(eventName, supportedProperties);
        }

        // Track login event and set up analytics related services when logging in.
        trackLogin(provider: string, userId: string) {
            loginAnalytics(userId);
            this.trackEvent(
                AnalyticsEvents.General_LoggedIn,
                { [AnalyticsProperties.LogInMethod]: this._authenticationMethod(provider) },
                { flush: true },
            );
        }

        trackLogout() {
            this.trackEvent(AnalyticsEvents.General_LoggedOut, {}, { flush: true });
        }

        /**
         * Logs out from Analytics integrations.
         * When loading Analytics it accepts the current user ID and it automatically updates
         * the session based on the received user ID, or sets it to null if not available.
         * This method only needs to get called when the user logs out asynchronously and they are
         * not redirected to a different page.
         */
        logoutAnalytics() {
            logoutAnalytics();
        }

        logoutOfChatWidgetProviders() {
            if (window.YNAB_CLIENT_CONSTANTS) {
                return YNAB.logoutOfChatWidgetProviders();
            }
        }

        // Track sign up event and set up analytics related services as if logging in.
        trackSignup(provider, userId) {
            if (this._isImpersonating()) {
                return;
            }
            if (window.gtmEvent) {
                window.gtmEvent("Completed Sign Up", {
                    signUpMethod: provider,
                    userId: userId,
                });
            }

            loginAnalytics(userId);
            this.trackEvent(
                AnalyticsEvents.SignUp_CompletedSignUp,
                {
                    [AnalyticsProperties.SignUpMethod]: this._authenticationMethod(provider),
                },
                { flush: true },
            );
        }

        _authenticationMethod(provider) {
            return provider == "apple" ? "Apple" : provider == "google" ? "Google" : "Email";
        }

        _isImpersonating() {
            return window.YNAB_CLIENT_CONSTANTS?.IMPERSONATING === true;
        }
    }

    window.YNABAnalytics = new YNABAnalytics();
})();
