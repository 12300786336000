import { ConsoleUtilities } from "ynab_shared_library/app/base/utilities/ConsoleUtilities";
import {
    AnalyticsEventDefinitions,
    AnalyticsEventProperties,
} from "ynab_shared_library/app/base/enums/AnalyticsEvents";
import { Amplitude } from "./amplitude";
import { Analytics } from "ynab_shared_library/app/base/utilities/Analytics";

// Sends an event to our analytics integration, validating that all properties are supported / existent. This is the main function to be
// used when logging events, unless there's a very explicit reason to use the lower level function `logAnalyticsRawEvent` directly.
export function logAnalyticsEvent<EventKey extends keyof AnalyticsEventDefinitions>(
    event: EventKey,
    properties: AnalyticsEventProperties<EventKey>,
    options?: { flush: boolean },
) {
    const propertyValues = Analytics.transformEventProperties(properties);
    console.log("Analytics Event:", event.toString(), propertyValues);
    logAnalyticsRawEvent(event.toString(), propertyValues, options);
}

// Sends the event to our analytics integration, assuming the properties have already been verified against our supported properties.
// Most of the time we're going to use the above function `logAnalyticsEvent` instead of this one, but this can be useful
// in certain contexts where we've pre-processed the event already. (like the event signals from SL used by web/mobile.)
export function logAnalyticsRawEvent(name: string, properties: any, options?: { flush: boolean }) {
    ConsoleUtilities.debug("Analytics Event:", name, properties);

    logAmplitudeRawEvent(name, properties, options);
}

function logAmplitudeRawEvent(name: string, properties: any, options?: { flush: boolean }) {
    Amplitude.track(name, properties);
    if (options?.flush) {
        Amplitude.flush();
    }
}

export function setAnalyticsUserProperties(properties: { [name: string]: string }): void {
    setAmplitudeUserProperties(properties);
}

function setAmplitudeUserProperties(properties: { [name: string]: string }): void {
    Amplitude.identify(properties);
}

export function loginAnalytics(userId: string) {
    loginAmplitude(userId);
}

function loginAmplitude(userId: string) {
    Amplitude.setUserId(userId);
}

export function logoutAnalytics() {
    logoutAmplitude();
}

function logoutAmplitude() {
    Amplitude.reset();
}
